import React, { useState, useEffect } from 'react';
import './App.css';
import Chat from './Chat';
import Modal from 'react-modal';
import Login from './Login';
import Subscription from './Subscription';
import Register from './Register';
import Sidebar from './Sidebar';
import RequestPasswordReset from './RequestPasswordReset';
import ResetPassword from './ResetPassword';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Swal from 'sweetalert2';

Modal.setAppElement('#root');

function App() {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('jwtToken')); // Retrieve token from localStorage
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [sidebarUpdate, setSidebarUpdate] = useState(false); // To trigger sidebar updates
    const [clearMessages, setClearMessages] = useState(() => () => {}); // Function to clear messages
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // New state for sidebar visibility

    const location = useLocation();
    const navigate = useNavigate();

    const handleLocationChange = () => {
        if (location.pathname === "/login") {
            setShowLoginModal(true);
            setShowRegisterModal(false);
        } else if (location.pathname === "/register") {
            setShowLoginModal(false);
            setShowRegisterModal(true);
        } else {
            setShowLoginModal(false);
            setShowRegisterModal(false);
        }
    };

    const fetchUser = async (token) => {
        if (!token) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const user = await response.json();
                setUser(user);
                if (user.hasActiveSubscription) {
                    setIsSubscribed(true);
                }
            } else {
                console.log('Failed to fetch user');
                localStorage.removeItem('jwtToken'); // Remove token if fetch fails
                setToken(null);
            }
        } catch (error) {
            console.error('Error fetching user:', error);
            localStorage.removeItem('jwtToken'); // Remove token if fetch fails
            setToken(null);
        }
    };

    const handleTokenInURL = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const resetToken = urlParams.get('resetToken');
        const error = urlParams.get('error');
        if (token) {
            localStorage.setItem('jwtToken', token);
            setToken(token);
            navigate('/');
        }
        if (resetToken) {
            navigate(`/reset-password?resetToken=${resetToken}`);
        }
        if (error === 'already_exists') {
            Swal.fire('Error', 'User already exists with email and password.', 'error');
            navigate('/');
        }
    };

    const verifyEmail = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const action = urlParams.get('action');
        const verifyToken = urlParams.get('token');
        if (action === 'verify-email' && verifyToken) {
            console.log('Attempting to verify email with token:', verifyToken); // Debugging statement
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/verify-email?token=${verifyToken}`);
                const result = await response.json();
                console.log('Verification response:', result); // Debugging statement
                if (result.success) {
                    Swal.fire('Success', result.message, 'success');
                    localStorage.setItem('jwtToken', result.token);
                    setToken(result.token);
                    fetchUser(result.token);
                    navigate('/');
                } else {
                    Swal.fire('Error', result.message, 'error');
                }
            } catch (error) {
                console.error('Error during email verification:', error); // Debugging statement
                Swal.fire('Error', 'An error occurred while verifying your email.', 'error');
            }
        }
    };

    useEffect(() => {
        verifyEmail();
        handleTokenInURL();
        handleLocationChange();
    }, [location]);

    useEffect(() => {
        fetchUser(token);
    }, [token]);

    const handleLogin = (token) => {
        setToken(token);
        navigate('/');
    };

    const handleSubscription = () => {
        setIsSubscribed(true);
        setShowSubscriptionModal(false);
    };

    const updateSidebar = () => {
        setSidebarUpdate(prev => !prev); // Toggle the state to force Sidebar re-fetch
    };

    const switchToRegister = () => {
        setShowLoginModal(false);
        setTimeout(() => setShowRegisterModal(true), 300); // Delay to ensure smooth transition
    };

    const switchToLogin = () => {
        setShowRegisterModal(false);
        setTimeout(() => setShowLoginModal(true), 300); // Delay to ensure smooth transition
    };

    return (
        <div className="App flex">
            <Sidebar 
                user={user} 
                updateSidebar={updateSidebar} 
                clearMessages={clearMessages} 
                setShowLoginModal={setShowLoginModal} 
                isSidebarOpen={isSidebarOpen} 
                setIsSidebarOpen={setIsSidebarOpen} 
            />
            <div className="flex-1">
                <Routes>
                    <Route
                        path="/"
                        element={<Chat
                            user={user}
                            setUser={setUser}
                            setShowLoginModal={setShowLoginModal}
                            setShowSubscriptionModal={setShowSubscriptionModal}
                            setShowRegisterModal={setShowRegisterModal}
                            isSubscribed={isSubscribed}
                            updateSidebar={updateSidebar} // Pass updateSidebar to Chat
                            setClearMessages={setClearMessages} // Pass setClearMessages to Chat
                            setShowSidebar={setIsSidebarOpen} // Pass setIsSidebarOpen to Chat
                        />}
                    />
                    <Route
                        path="/c/:id"
                        element={<Chat
                            user={user}
                            setUser={setUser}
                            setShowLoginModal={setShowLoginModal}
                            setShowSubscriptionModal={setShowSubscriptionModal}
                            setShowRegisterModal={setShowRegisterModal}
                            isSubscribed={isSubscribed}
                            updateSidebar={updateSidebar} // Pass updateSidebar to Chat
                            setClearMessages={setClearMessages} // Pass setClearMessages to Chat
                            setShowSidebar={setIsSidebarOpen} // Pass setIsSidebarOpen to Chat
                        />}
                    />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/request-password-reset" element={<RequestPasswordReset />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                </Routes>
            </div>

            <Modal
                isOpen={showLoginModal}
                onRequestClose={() => setShowLoginModal(false)}
                contentLabel="Login Modal"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <Login onLogin={handleLogin} onRegister={switchToRegister} />
            </Modal>

            <Modal
                isOpen={showSubscriptionModal}
                onRequestClose={() => setShowSubscriptionModal(false)}
                contentLabel="Subscription Modal"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <Subscription onSubscription={handleSubscription} onClose={() => setShowSubscriptionModal(false)} />
            </Modal>

            <Modal
                isOpen={showRegisterModal}
                onRequestClose={() => setShowRegisterModal(false)}
                contentLabel="Register Modal"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <Register onRegister={switchToLogin} />
            </Modal>
        </div>
    );
}

export default App;
