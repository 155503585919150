import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { XMarkIcon } from '@heroicons/react/24/solid';  // Assuming you have heroicons installed

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Subscription = ({ onSubscription, onClose }) => {
    const handleClick = async () => {
        const stripe = await stripePromise;

        const token = localStorage.getItem('jwtToken');
        if (!token) {
            alert("You need to be logged in");
            return;
        }
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/create-checkout-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Send token in Authorization header
            },
        });

        const session = await response.json();

        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            console.error(result.error.message);
        } else {
            onSubscription();
        }
    };

    return (
        <div className="relative p-6 bg-white rounded-md shadow-md max-w-md mx-auto md:max-w-lg lg:max-w-xl">
            <button onClick={onClose} className="absolute top-2 right-2">
                <XMarkIcon className="h-6 w-6 text-gray-500 hover:text-gray-700" />
            </button>
            <h2 className="text-2xl font-bold mb-4 text-center">Hey there, Adventurer!</h2>
            <p className="text-gray-700 mb-4">
                Welcome to the ultimate realm of ChatNSFW, where your curiosity knows no bounds and every conversation is an epic journey! 🌟 Ready to level up and unlock the treasure trove of premium features? Here’s what awaits you:
            </p>
            <ul className="list-disc list-inside mb-4 text-gray-700">
                <li>Unlimited conversations!</li>
                <li>NSFW content without restrictions!</li>
                <li>Priority response times!</li>
                <li>Early access to new features!</li>
            </ul>
            <p className="text-gray-700 mb-6">
                Upgrade now and embrace a chatting experience like no other.
            </p>
            <div className="text-center">
                <button
                    role="link"
                    onClick={handleClick}
                    className="w-full py-2 px-4 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700"
                >
                    Subscribe Now
                </button>
                <p className="text-gray-700 text-sm mt-6">
                $15/month - Cancel anytime
            </p>
            </div>
        </div>
    );
};

export default Subscription;
