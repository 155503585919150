import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Router>
      <Routes>
        <Route path="/*" element={<App />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
);
