import React, { useState } from 'react';

function Login({ onLogin, onRegister }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous error message
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
            const result = await response.json();
            if (result.success) {
                localStorage.setItem('jwtToken', result.token); // Store token in localStorage
                onLogin(result.token);
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Error logging in:', error);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <div className="mt-1">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">Password</label>
                <div className="mt-1">
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
            </div>
            {error && (
                <div className="text-red-500 text-sm">
                    {error}
                </div>
            )}
            <div>
                <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Login
                </button>
            </div>
            <div className="relative mt-6">
                <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Or</span>
                </div>
            </div>
            <div>
                <a href={`${process.env.REACT_APP_SERVER_URL}/auth/google`} className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                    Continue with Google
                </a>
            </div>
            <div className="text-center mt-4">
                <button type="button" onClick={onRegister} className="text-blue-500 hover:underline">
                    Don't have an account? Register
                </button>
            </div>
            <div className="text-center mt-4">
                <a href="/request-password-reset" className="text-blue-500 hover:underline">
                    Forgot your password?
                </a>
            </div>
            <div className="text-xs text-gray-500 mt-4">
                By signing in you agree to our <a href="/terms-of-service" className="text-blue-500">Terms of Service</a> and <a href="/privacy-policy" className="text-blue-500">Privacy Policy</a>.
            </div>
        </form>
    );
}

export default Login;
