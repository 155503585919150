import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './App.css';  // Assuming you have some global styles, especially for animations
import { Bars3Icon, DocumentDuplicateIcon } from '@heroicons/react/24/solid';  // Assuming you have heroicons installed

// Truncate text utility function
function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + '...';
}

// Function to replace \n with <br /> tags
function formatText(text) {
    return text
        .replace(/\n/g, '<br />')
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/\【(.*?)\】/g, '');
}

export default function Chat({ user, setUser, setShowLoginModal, setShowSubscriptionModal, setShowRegisterModal, isSubscribed, setClearMessages, setShowSidebar }) {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false); // New state for loading
    const [copiedIndex, setCopiedIndex] = useState(null); // New state for copying
    const [autoScroll, setAutoScroll] = useState(true); // New state for autoscroll
    const messagesEndRef = useRef(null); // Ref for automatic scrolling
    const containerRef = useRef(null); // Ref for message container
    const { id } = useParams(); // Get conversation ID from URL
    const navigate = useNavigate();

    const scrollToBottom = () => {
        if (autoScroll && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView();
        }
    };

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollHeight - scrollTop === clientHeight) {
                setAutoScroll(true);
            } else {
                setAutoScroll(false);
            }
        }
    };

    useEffect(() => {
        const fetchConversation = async () => {
            if (id) {
                const token = localStorage.getItem('jwtToken');
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/conversations/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const conversation = await response.json();
                setMessages(conversation.messages || []);
            }
        };

        fetchConversation();
    }, [id]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const sendMessage = async () => {
        if (input.trim() === "") return;

        const token = localStorage.getItem('jwtToken');
        if (!token) {
            setShowLoginModal(true);
            return;
        }

        const newMessage = { role: 'user', content: input };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInput(''); // Clear the input field immediately
        setLoading(true); // Show loading spinner

        try {
            let conversationId = id;

            if (!conversationId) {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/conversations`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 403) {
                    setShowSubscriptionModal(true);
                    setLoading(false);
                    return;
                }

                const newConversation = await response.json();
                conversationId = newConversation.id;
                navigate(`/c/${conversationId}`);
            }

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Send token in Authorization header
                },
                body: JSON.stringify({ messages: [...messages, newMessage], conversationId }),
            });

            if (response.status === 401) {
                setShowLoginModal(true);
                setLoading(false); // Hide loading spinner
                return;
            }

            if (response.status === 403) {
                setShowSubscriptionModal(true);
                setLoading(false);
                return;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let done = false;
            let buffer = '';

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                buffer += decoder.decode(value, { stream: true });

                let boundary = buffer.indexOf('\n');
                while (boundary !== -1) {
                    const chunk = buffer.slice(0, boundary);
                    buffer = buffer.slice(boundary + 1);

                    if (chunk.trim()) {
                        const { role, content } = JSON.parse(chunk);

                        setMessages(prevMessages => {
                            const newMessages = [...prevMessages];
                            const lastMessage = newMessages[newMessages.length - 1];
                            if (lastMessage && lastMessage.role === 'assistant') {
                                newMessages[newMessages.length - 1].content = content;
                            } else {
                                newMessages.push({ role, content });
                            }
                            return newMessages;
                        });

                        // Hide the loading spinner once the first chunk is received
                        setLoading(false);
                    }

                    boundary = buffer.indexOf('\n');
                }
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: "Error processing your request." }]);
            setLoading(false); // Hide loading spinner
        }
    };

    const clearMessages = () => {
        setMessages([]);
    };

    useEffect(() => {
        setClearMessages(() => clearMessages);
    }, [setClearMessages]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    const copyToClipboard = (text, index) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedIndex(index);
            setTimeout(() => setCopiedIndex(null), 2000); // Hide "Copied" after 2 seconds
        });
    };

    return (
        <div className="h-screen flex flex-col bg-gray-900 text-white relative">
            <div className="flex justify-between items-center h-16 bg-gray-800 p-4">
                <button onClick={() => setShowSidebar(true)} className="md:hidden">
                    <Bars3Icon className="h-8 w-8 text-white" />
                </button>
                <h1 className="text-2xl font-bold">ChatNSFW.com</h1>
            </div>

            <div className="flex-1 p-4 overflow-auto" ref={containerRef} onScroll={handleScroll}>
                {messages.length > 0 ? (
                    messages.map((msg, index) => (
                        <div key={index} className={`p-2 mb-2 rounded-lg ${msg.role === 'assistant' ? 'text-left bg-gray-300 text-black' : 'bg-gray-800 text-left'}`}>
                            <span dangerouslySetInnerHTML={{ __html: formatText(msg.content) }} />
                            {msg.role === 'assistant' && (
                                <div className="flex items-center mt-2">
                                    <button
                                        onClick={() => copyToClipboard(msg.content, index)}
                                        className="flex items-center text-sm text-blue-500 hover:text-blue-700"
                                    >
                                        <DocumentDuplicateIcon className="h-5 w-5 mr-1" />
                                        Copy
                                    </button>
                                    {copiedIndex === index && (
                                        <span className="ml-2 text-green-500 text-sm">
                                            Copied &#10003;
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <p>Unleash your curiosity and dive into the unfiltered world of ChatNSFW—where every conversation is limitless and no topic is off-limits. What should we discuss? Ask anything!</p>
                )}
                {loading && (
                    <div className="p-2 mb-2 rounded-lg bg-gray-300 text-black">
                        <div className="loader">...</div>
                    </div>
                )}
                <div ref={messagesEndRef} />
            </div>

            <div className="flex flex-col p-4">
                <div className="flex items-center">
                    <textarea
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                        className="flex-1 bg-gray-800 border border-gray-700 text-white px-4 py-2 rounded"
                        placeholder="Message ChatNSFW..."
                        rows={2}
                    />
                    <button onClick={sendMessage} className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Send
                    </button>
                </div>
                <p className="mt-2 text-xs text-gray-400">
                    ChatNSFW is an experimental project and may provide incorrect information. Do not rely on its advice. Need help ? <a href="mailto:hello@chatnsfw.com" style={{textDecoration: "underline"}}>contact us</a>.
                </p>
            </div>
        </div>
    );
}
