import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TrashIcon, EllipsisVerticalIcon, PlusCircleIcon, PencilIcon } from '@heroicons/react/24/solid';

function Sidebar({ user, updateSidebar, clearMessages, isSidebarOpen, setIsSidebarOpen, setShowLoginModal }) {
    const [conversations, setConversations] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [editingTitleId, setEditingTitleId] = useState(null);
    const [newTitle, setNewTitle] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchConversations = async () => {
            const token = localStorage.getItem('jwtToken');
            if (!token) return; // If no token, return early

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/conversations`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setConversations(data);
            } else {
                console.error('Failed to fetch conversations');
            }
        };

        fetchConversations();
    }, [updateSidebar]);

    const handleNewConversation = async () => {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            setShowLoginModal(true);
            return;
        }
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/conversations`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        const newConversation = await response.json();
        setConversations(prev => [newConversation, ...prev].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))); // Prepend and sort
        navigate(`/c/${newConversation.id}`);
    };

    const handleDeleteConversation = async (id) => {
        const token = localStorage.getItem('jwtToken');
        await fetch(`${process.env.REACT_APP_SERVER_URL}/conversations/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        setConversations(conversations.filter(c => c.id !== id));
        if (location.pathname === `/c/${id}`) {
            navigate('/');
            clearMessages(); // Clear messages when a conversation is deleted
            window.location.reload(); // Force a page reload to ensure the chatbox is cleared
        }
    };

    const handleRenameConversation = async (id) => {
        const token = localStorage.getItem('jwtToken');
        await fetch(`${process.env.REACT_APP_SERVER_URL}/conversations/${id}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ title: newTitle }),
        });
        setConversations(prev => prev.map(conversation => 
            conversation.id === id ? { ...conversation, title: newTitle } : conversation
        ));
        setEditingTitleId(null);
        setNewTitle('');
    };

    // Define the truncateText function
    function truncateText(text, maxLength) {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    }

    return (
        <>
            {isSidebarOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-20" onClick={() => setIsSidebarOpen(false)}></div>
            )}
            <div className={`h-screen w-64 bg-gray-800 text-white flex flex-col p-4 fixed z-30 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 transition-transform duration-200 ease-in-out`}>
                <div className="mb-6">
                    <h2 className="text-2xl font-bold mb-2"></h2>
                    {user ? (
                        <div>
                            <p>Logged in as:</p>
                            <p className="font-bold">{user.email}</p>
                            {user.hasActiveSubscription && (
                                <a
                                    href="https://billing.stripe.com/p/login/dR6aII5np0w29lScMM"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 underline hover:text-blue-700 mt-4 block"
                                >
                                    Manage Subscription
                                </a>
                            )}
                            <button
                                onClick={() => { localStorage.removeItem('jwtToken'); window.location.reload(); }}
                                className="mt-4 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                            >
                                Logout
                            </button>
                        </div>
                    ) : (
                        <p>Please log in</p>
                    )}
                </div>
                <div className="flex-1">
                    <button
                        onClick={handleNewConversation}
                        className="w-full mb-4 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 flex items-center justify-center"
                    >
                        <PlusCircleIcon className="h-5 w-5 mr-2" />
                        New Chat
                    </button>
                    <ul>
                        {conversations.map(conversation => (
                            <li
                                key={conversation.id}
                                className={`mb-2 flex justify-between items-center p-2 rounded-lg cursor-pointer ${location.pathname === `/c/${conversation.id}` ? 'bg-blue-700' : 'bg-gray-700 hover:bg-gray-600'}`}
                            >
                                <button
                                    onClick={() => navigate(`/c/${conversation.id}`)}
                                    className="flex-1 text-left"
                                >
                                    {editingTitleId === conversation.id ? (
                                        <input
                                            type="text"
                                            value={newTitle}
                                            placeholder={conversation.title || conversation.id}
                                            onChange={(e) => setNewTitle(e.target.value)}
                                            onBlur={() => handleRenameConversation(conversation.id)}
                                            onFocus={() => setDropdownOpen(null)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleRenameConversation(conversation.id);
                                                }
                                            }}
                                            className="bg-gray-800 text-white border-b border-gray-600 focus:outline-none"
                                            autoFocus
                                        />
                                    ) : (
                                        truncateText(conversation.title || conversation.id, 20)
                                    )}
                                </button>
                                <div className="relative">
                                    <button
                                        onClick={() => setDropdownOpen(dropdownOpen === conversation.id ? null : conversation.id)}
                                        className="ml-2 text-gray-400 hover:text-gray-600"
                                    >
                                        <EllipsisVerticalIcon className="h-5 w-5" />
                                    </button>
                                    {dropdownOpen === conversation.id && (
                                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                                            <button
                                                onClick={() => {
                                                    setEditingTitleId(conversation.id);
                                                    setNewTitle(conversation.title || '');
                                                    setDropdownOpen(null);
                                                }}
                                                className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 w-full"
                                            >
                                                <PencilIcon className="h-5 w-5 mr-2 text-gray-500" />
                                                Rename
                                            </button>
                                            <button
                                                onClick={() => handleDeleteConversation(conversation.id)}
                                                className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 w-full"
                                            >
                                                <TrashIcon className="h-5 w-5 mr-2 text-gray-500" />
                                                Delete
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Sidebar;
