import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-2xl">
            <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
            <p className="text-gray-700 mb-4">
                At Chat NSFW, we prioritize the protection of your privacy and personal information.
            </p>
            <h2 className="text-2xl font-bold mb-4">Information Collection</h2>
            <p className="text-gray-700 mb-4">
                We collect information necessary to provide our AI-powered services, including:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Account information (email, encrypted authentication credentials)</li>
                <li>Usage data and analytics</li>
                <li>Data processed through our Mixtral LLM model</li>
                <li>Technical information required for service operation</li>
            </ul>
            <h2 className="text-2xl font-bold mb-4">Data Usage and Processing</h2>
            <p className="text-gray-700 mb-4">
                We use your information to:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Deliver and improve our AI analytics services</li>
                <li>Ensure platform security and performance</li>
                <li>Provide technical support and service updates</li>
                <li>Comply with legal obligations</li>
            </ul>
            <h2 className="text-2xl font-bold mb-4">Data Security Measures</h2>
            <p className="text-gray-700 mb-4">
                We implement industry-standard security protocols including:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>End-to-end encryption for data transmission</li>
                <li>Secure data storage with encryption at rest</li>
                <li>Regular security audits and updates</li>
                <li>Strict access controls and authentication</li>
            </ul>
            <h2 className="text-2xl font-bold mb-4">User Data Rights</h2>
            <p className="text-gray-700 mb-4">
                You maintain control over your data with rights to:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Access your personal information</li>
                <li>Request data modification or deletion</li>
                <li>Export your data</li>
                <li>Opt-out of non-essential data processing</li>
            </ul>
            <h2 className="text-2xl font-bold mb-4">Third-Party Services</h2>
            <p className="text-gray-700 mb-4">
                We carefully select third-party service providers who meet our strict privacy and security standards. These providers are contractually obligated to protect your data and may only process it for specified purposes.
            </p>
            <h2 className="text-2xl font-bold mb-4">Data Retention</h2>
            <p className="text-gray-700 mb-4">
                We retain data only as long as necessary to provide our services or as required by law. When data is no longer needed, it is securely deleted according to our data retention policies.
            </p>
            <h2 className="text-2xl font-bold mb-4">Policy Updates</h2>
            <p className="text-gray-700 mb-4">
                We may update this policy to reflect changes in our practices or legal requirements. Users will be notified of significant changes, and continued use of our services constitutes acceptance of the updated policy.
            </p>
            <h2 className="text-2xl font-bold mb-4">Contact Information</h2>
            <p className="text-gray-700 mb-4">
                For privacy-related inquiries or to exercise your data rights, please contact our Data Protection team through our official support channels.
            </p>
            <div className="flex justify-between">
                <Link to="/register" className="text-blue-500">Return to Registration</Link>
            </div>
        </div>
    </div>
);

export default PrivacyPolicy;