import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [resetToken, setResetToken] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const tokenParam = urlParams.get('resetToken');
        if (tokenParam) {
            setResetToken(tokenParam);
        } else {
            navigate('/'); // Redirect if no resetToken is present
        }
    }, [location, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous error message

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: resetToken, newPassword: password }), // Note the field name newPassword
            });
            const result = await response.json();
            if (result.success) {
                Swal.fire('Success', 'Password reset successful. You can now log in with your new password.', 'success');
                navigate('/login');
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div>
                <label className="block text-sm font-medium text-gray-700">New Password</label>
                <div className="mt-1">
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
            </div>
            {error && (
                <div className="text-red-500 text-sm">
                    {error}
                </div>
            )}
            <div>
                <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Reset Password
                </button>
            </div>
        </form>
    );
}

export default ResetPassword;
