import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-2xl">
            <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
            <p className="text-gray-700 mb-4">
                Welcome to Chat NSFW Platform. These terms outline the rules and guidelines for using our services. By accessing our platform, you accept these terms. If you disagree with any part of the terms, please do not use our services.
            </p>
            <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
            <p className="text-gray-700 mb-4">
                Our platform is an AI-powered analytics solution utilizing the Mixtral LLM model for data processing and analysis.
            </p>
            <h2 className="text-2xl font-bold mb-4">2. User Responsibilities</h2>
            <p className="text-gray-700 mb-4">
                Users must comply with all applicable laws and regulations. Any violation of these terms or misuse of the platform may result in immediate account termination. Users are responsible for all activities conducted through their accounts.
            </p>
            <h2 className="text-2xl font-bold mb-4">3. Content and Data Processing</h2>
            <p className="text-gray-700 mb-4">
                Our AI-powered platform processes data and generates content based on user inputs. While we strive for accuracy, users should independently verify any critical information. We maintain strict content moderation standards and reserve the right to refuse processing of any inappropriate content.
            </p>
            <h2 className="text-2xl font-bold mb-4">4. Data Security and Privacy</h2>
            <p className="text-gray-700 mb-4">
                We implement industry-standard security measures to protect user data. All information is encrypted during transmission and storage. We comply with applicable data protection regulations and maintain a comprehensive privacy policy available on our website.
            </p>
            <h2 className="text-2xl font-bold mb-4">5. Limitation of Liability</h2>
            <p className="text-gray-700 mb-4">
                Our services are provided "as is." We are not liable for any damages arising from the use of our platform, including but not limited to direct, indirect, incidental, or consequential damages.
            </p>
            <h2 className="text-2xl font-bold mb-4">6. Indemnification</h2>
            <p className="text-gray-700 mb-4">
                Users agree to indemnify and hold us harmless from any claims, losses, or damages resulting from their use of the platform or violation of these terms.
            </p>
            <h2 className="text-2xl font-bold mb-4">7. Technical Requirements</h2>
            <p className="text-gray-700 mb-4">
                Users are responsible for maintaining compatible software and hardware required to access our services. We regularly update our platform to improve performance and security.
            </p>
            <h2 className="text-2xl font-bold mb-4">8. Data Retention</h2>
            <p className="text-gray-700 mb-4">
                We maintain data in accordance with our data retention policies and applicable laws. Users are responsible for backing up any critical information processed through our platform.
            </p>
            <h2 className="text-2xl font-bold mb-4">9. Modifications to Terms</h2>
            <p className="text-gray-700 mb-4">
                We reserve the right to update these terms as needed. Users will be notified of significant changes, and continued use of the platform constitutes acceptance of modified terms.
            </p>
            <h2 className="text-2xl font-bold mb-4">10. Contact Information</h2>
            <p className="text-gray-700 mb-4">
                For questions or concerns regarding these terms, please contact our support team through the official support channels listed on our website or email support@chatnsfw.com
            </p>
            <div className="flex justify-between">
                <Link to="/register" className="text-blue-500">Return to Registration</Link>
            </div>
        </div>
    </div>
);

export default TermsOfService;